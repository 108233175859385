import React, { useEffect, useState } from "react";
import { BoxPlotChart } from "./components/BoxPlotChart";
import IRatingGraph from "./components/IRatingGraph";
import { TopNav } from "./components/TopNav";
import { HomePage } from "./components/HomePage";
import IRatingPerClub from "./components/IRatingPerClub";
import { SeasonSummaryMulti } from "./components/SeasonSummaryMulti";
import SeriesPopularity from "./components/SeriesPopularity";
import { IncidentsPerClub } from "./components/IncidentsPerClub";
import { LegacyTimesPerCar } from "./components/LegacyTimesPerCar";
import SeriesSafety from "./components/SeriesSafety";
import PrivacyPolicy from "./components/PrivacyPolicy";
import ContactMe from "./components/ContactMe";
import CareerStats from "./components/CareerStats";
import SeriesLapTimeChart from "./components/SeriesLapTimeChart";
import { LoginPage } from "./components/LoginPage";
import { RegisterPage } from "./components/RegisterPage";
import SupportPage from "./components/SupportPage";
import RaceResultsPage from "./components/RaceResultsPage";
import ForgotPasswordPage from "./components/ForgotPasswordPage";
import AdSenseScript from "./components/AdSenseScript";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { WorldRecordsPage } from "./components/WorldRecordsPage";
import { WorldRecordsResultPage } from "./components/WorldRecordsResultPage";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

function App() {
        const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem("token"));

        // Retrieve the isPaidUser value from localStorage and convert it to a boolean for the initial state
        const initialIsPaidUser = localStorage.getItem("isPaidUser") === "true";

        // Create a state for shouldLoadAds
        const [shouldLoadAds, setShouldLoadAds] = useState(!initialIsPaidUser);

        useEffect(() => {
                // Update shouldLoadAds based on the isPaidUser value in localStorage whenever isLoggedIn changes
                const isPaidUser = localStorage.getItem("isPaidUser") === "true";
                setShouldLoadAds(!isPaidUser);
        }, [isLoggedIn]);

        return (
                <Router>
                        <div className="App">
                                <AdSenseScript shouldLoadAds={shouldLoadAds} />
                                <TopNav isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />

                                <Routes>
                                        <Route path="/" element={<HomePage />} />
                                        <Route path="/charts/iRating" element={<IRatingGraph />} />
                                        <Route path="/charts/SeriesSafety" element={<SeriesSafety />} />
                                        <Route path="/charts/VRS" element={<BoxPlotChart season={"GT Sprint Series by Simucube - 2025 Season 2"} />} />
                                        <Route path="/charts/FanatecFixed" element={<BoxPlotChart season={"GT3 Challenge Fixed by Fanatec - 2025 Season 2 - Fixed"} />} />
                                        <Route path="/charts/PCC" element={<BoxPlotChart season={"Production Car Challenge by Sim-Lab - 2025 Season 2"} />} />
                                        <Route path="/charts/SCC" element={<BoxPlotChart season={"Sports Car Challenge by Falken Tyre - 2025 Season 2"} />} />
                                        <Route path="/charts/GT4Fixed" element={<BoxPlotChart season={"GT4 Falken Tyre Challenge - 2025 Season 2 Fixed"} />} />
                                        <Route path="/charts/IMSA" element={<BoxPlotChart season={"IMSA iRacing Series - 2025 Season 2"} />} />
                                        <Route path="/charts/IMSAFixed" element={<BoxPlotChart season={"IMSA iRacing Series - Fixed - 2025 Season 2"} />} />
                                        <Route path="/charts/IMSAEndurance" element={<BoxPlotChart season={"IMSA Endurance Series - 2025 Season 2"} />} />
                                        <Route path="/charts/TCR" element={<BoxPlotChart season={"TCR Virtual Challenge - 2025 Season 2"} />} />
                                        <Route path="/charts/TCRFixed" element={<BoxPlotChart season={"TCR Virtual Challenge - Fixed - 2025 Season 2"} />} />
                                        <Route path="/charts/RingMeister" element={<BoxPlotChart season={"Ring Meister by Ricmotech - 2025 Season 2"} />} />
                                        <Route path="/charts/IRatingPerClub" element={<IRatingPerClub />} />
                                        <Route path="/charts/IncidentsPerClub" element={<IncidentsPerClub />} />
                                        <Route path="/charts/SeriesPopularity" element={<SeriesPopularity />} />
                                        <Route path="/charts/SeasonSummaryMulti" element={<SeasonSummaryMulti />} />
                                        <Route path="about/privacypolicy" element={<PrivacyPolicy />} />
                                        <Route path="about/Contact" element={<ContactMe />} />
                                        <Route path="user/careerstats/:custid?" element={<CareerStats />} />
                                        <Route path="charts/SeriesLapTimeChart/:season_name?" element={<SeriesLapTimeChart />} />
                                        <Route path="/login" element={<LoginPage setIsLoggedIn={setIsLoggedIn} />} />
                                        <Route path="/register" element={<RegisterPage />} />
                                        <Route path="/forgot-password" element={<ForgotPasswordPage />} />
                                        <Route path="/support" element={<SupportPage />} />
                                        <Route path="/RaceResults/:subsession_id" element={<RaceResultsPage />} />
                                        <Route path="/WorldRecords" element={<WorldRecordsPage />} />
                                        <Route path="/world-records/:car_id/:track_id" element={<WorldRecordsResultPage />} />
                                </Routes>
                        </div>
                </Router>
        );
}
export default App;
